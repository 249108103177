import { GET_SHOPS, SET_SHOPS_SELECTED_PRODUCT, SET_SHOPS_SELECTED_SHOP, SET_SHOPS_SEARCH_TERM } from '../actionTypes';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

const initialState = {
  allIds: [],
  byId: {},
  searchTerm: '',
  selectedShop: 0,
  selectedProduct: 0,
};

const byId = (state = initialState.byId, { type, payload = null }) => {
  switch(type) {
    case GET_SHOPS:
      return getShopsById(state, payload);
    default:
      return state;
  }
}

function getShopsById(state, payload) {
  return {
    ...state,
    ...payload.data.reduce((obj, shop) => { 
      obj[shop.id] = shop;
      return obj;
    }, {})
  }
}


const allIds = (state = initialState.allIds, { type, payload = null}) => {
  switch(type) {
    case GET_SHOPS:
      return getShops(state, payload);
    default:
      return state;
  }
}

function getShops(state, payload) {
  return payload.data.map(shop => shop.id);
}

const searchTerm = (state = initialState.searchTerm, { type, payload = null}) => {
  switch(type) {
    case SET_SHOPS_SEARCH_TERM:
      return payload;
    default:
      return state;
  }
}

const selectedShop = (state = initialState.selectedShop, { type, payload = null}) => {
  switch(type) {
    case SET_SHOPS_SELECTED_SHOP:
      return payload;
    default:
      return state;
  }
}

const selectedProduct = (state = initialState.selectedProduct, { type, payload = null}) => {
  switch(type) {
    case SET_SHOPS_SELECTED_PRODUCT:
      return payload;
    default:
      return state;
  }
}

const selectShopsById = state => state.shops.byId;
const selectShopsAllIds = state => state.shops.allIds;

const selectShopId = state => state.shops.selectedShop;
const selectProductId = state => state.shops.selectedProduct;

const selectSearchTerm = state => state.shops.searchTerm;

export const selectShop = createSelector([selectShopsById, selectShopId], (shops, id) => shops[id]);

export const selectProducts = createSelector([selectShop], (shop) => {
  let products = [];
  if (shop && shop.categories) {
    for (let i = 0; i < shop.categories.length; i++) { 
      if (shop.categories[i].products) {
        products = [...products, ...shop.categories[i].products];
      }
    }
  }
  return products;
});

export const selectProduct = createSelector([selectProducts, selectProductId], (products, productId) => {  
  return products.filter(p => p.id === productId)[0];
});

export const selectVariations = createSelector([selectProduct], product => {
  let variations = [];
  if (product) { 
    variations = product.available_variations
  }
  return variations;
});

export const selectBeanVariations = createSelector([selectVariations], variations => {
  return variations.filter(v => v.type.name === 'Beans');
});

export const selectMilkVariations = createSelector([selectVariations], variations => {
  return variations.filter(v => v.type.name === 'Milk');
});

export const selectSizeVariations = createSelector([selectVariations], variations => {
  return variations.filter(v => v.type.name === 'Size');
});

export const selectStrengthVariations = createSelector([selectVariations], variations => {
  return variations.filter(v => v.type.name === 'Strength');
});

export const selectExtras = createSelector([selectProduct], product => {
  let extras = [];
  if (product) {
    extras = product.available_extras;
  }
  return extras;
});

export const selectIncrementableExtras = createSelector([selectExtras], extras => {
  return extras.filter(e => e.type.name === 'Sugar');
});

export const selectNonIncrementableExtras = createSelector([selectExtras], extras => {
  const nonIncrementableExtras = extras.filter(e => e.type.name != 'Sugar');
  
  const nonIncrementableExtrasByType = nonIncrementableExtras.reduce((acc, cur) => {
    if(typeof acc[cur.type.id] === 'undefined') {
      acc[cur.type.id] = {
        type: cur.type,
        extras: []
      }
    }

    acc[cur.type.id].extras.push({...cur, name: `${cur.name.charAt(0).toUpperCase()}${cur.name.slice(1)}`});

    return acc;
  }, {});

  return nonIncrementableExtrasByType;
});


export const selectShopsBySearchTerm = createSelector(
  [selectShopsAllIds, selectShopsById, selectSearchTerm], 
  (shopsAllIds, shopsById, searchTerm) => {
    let shops = [...shopsAllIds];

    if (searchTerm !== '') {
      shops = shops.filter(id => {
        if (searchTerm.includes(' ')) {
          const terms = searchTerm.split(' ');

          for (let i = 0; i < terms.length; i++) {
            if (shopsById[id].name.toLowerCase().includes(terms[i].toLowerCase())) {
              return true;
            };
          }
        } else {
          return shopsById[id].name.toLowerCase().includes(searchTerm.toLowerCase());
        }
      });
    }

    return shops.map(id => shopsById[id]);
  }
)

export default combineReducers({
  byId,
  allIds,
  searchTerm,
  selectedShop,
  selectedProduct
});