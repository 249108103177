import React, { Component } from "react";
import { connect } from "react-redux";
import { RouterNavigator, RouterUtil } from "react-onsenui";
import { selectCurrentOrders } from "../reducers/user";
import CurrentOrders from "./orders/CurrentOrders";

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeConfig: RouterUtil.init([
        {
          component: props.tab.component,
          props: {
            key: "home",
            pushPage: this.pushPage.bind(this),
            setActiveTab: props.setActiveTab,
          },
        },
      ]),
      popInProgress: false,
    };
  }

  componentWillUnmount() {
    this.setState({ popInProgress: false });
  }

  pushPage(page, key, props) {
    const { routeConfig } = this.state;

    const newRouteConfig = RouterUtil.push({
      routeConfig,
      route: {
        component: page,
        props: {
          key: key,
          popPage: this.popPage.bind(this),
          pushPage: this.pushPage.bind(this),
          resetPageStack: this.resetPageStack.bind(this),
          resetPageStackToCurrentOrder:
            this.resetPageStackToCurrentOrder.bind(this),
          ...props,
        },
      },
    });

    this.props.onStackChange(newRouteConfig.routeStack.length);
    this.setState({ routeConfig: newRouteConfig });
  }

  popPage(options = {}) {
    if (this.state.popInProgress) {
      console.warn("popPage is already running.");
      return;
    }

    this.setState({ popInProgress: true }, () => {
      setTimeout(() => this.setState({ popInProgress: false }), 2000);

      try {
        const { routeConfig } = this.state;
        const newRouteConfig = RouterUtil.pop({ routeConfig, options });
        this.props.onStackChange(newRouteConfig.routeStack.length);
        this.setState({ routeConfig: newRouteConfig, popInProgress: false });
      } catch (error) {
        console.error("Error while popping page:", error);
        this.setState({ popInProgress: false });
      }
    });
  }

  resetPageStackToCurrentOrder() {
    let { routeConfig } = this.state;

    routeConfig = RouterUtil.reset({
      routeConfig,
      route: {
        component: this.props.tab.component,
        props: {
          key: "home",
          pushPage: this.pushPage.bind(this),
        },
      },
    });

    routeConfig = RouterUtil.push({
      routeConfig,
      route: {
        component: CurrentOrders,
        props: {
          key: "current-orders",
          currentOrders: this.props.currentOrders,
          popPage: this.popPage.bind(this),
          pushPage: this.pushPage.bind(this),
          resetPageStack: this.resetPageStack.bind(this),
        },
      },
    });

    this.props.onStackChange(1);
    this.setState({ routeConfig });
  }

  resetPageStack() {
    const { routeConfig } = this.state;

    const newRouteConfig = RouterUtil.reset({
      routeConfig,
      route: {
        component: this.props.tab.component,
        props: {
          key: "home",
          pushPage: this.pushPage.bind(this),
        },
      },
    });

    this.props.onStackChange(1);
    this.setState({ routeConfig: newRouteConfig });
  }

  onPostPush = () => {
    this.setState({ routeConfig: RouterUtil.postPush(this.state.routeConfig) });
  };

  onPostPop = () => {
    this.setState({ routeConfig: RouterUtil.postPop(this.state.routeConfig) });
  };

  renderPage(route) {
    const { component: Component, props = {} } = route;
    return <Component key={props.key} {...props} />;
  }

  render() {
    const { routeConfig } = this.state;

    return (
      <RouterNavigator
        swipeable={true}
        swipePop={(options) => this.popPage(options)}
        routeConfig={routeConfig}
        renderPage={this.renderPage}
        onPostPush={this.onPostPush}
        onPostPop={this.onPostPop}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  isAuthenticated: state.user.loggedIn,
  currentOrders: selectCurrentOrders(state),
});

export default connect(mapStateToProps)(Navigator);
