import { storePushSubscription } from "./api/api";

export default class ServiceWorker {
  constructor() {
    this.vapidPublicKey =
      "BFkxZrf5rb-FbJ5tjlm0KqYusF3xMxJzpwkMR96ogpPqmlR91eYV2iCI-95R_F_Mi_OgkLli4y0_cmRBm9wa2eU";
    this.isSafari =
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1;
  }

  init = async () => {
    if (!"serviceWorker" in navigator) {
      //service worker isn't supported
      return;
    }

    //don't use it here if you use service worker
    //for other stuff.
    if (!"PushManager" in window) {
      //push isn't supported
      return;
    }

    //register the service worker
    try {
      await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);
      console.log("serviceWorker installed!");

      if (this.isSafari) {
        if (
          navigator.serviceWorker &&
          window.PushManager &&
          window.Notification &&
          window.Notification.permission === "default"
        ) {
          document.body.addEventListener("click", async (event) => {
            if (event.target.id == "allow_notifications_btn") {
              await navigator.serviceWorker.getRegistration();
              this.subscribeUser();
            }
          });
        }
      } else {
        this.initPush();
      }
    } catch (error) {
      console.log(error);
    }
  };

  initPush = async () => {
    if (!navigator.serviceWorker.ready) {
      return;
    }

    try {
      const permissionResult = await window.Notification.requestPermission();

      if (permissionResult !== "granted") {
        console.log("We weren't granted permission.");
        return;
      }

      this.subscribeUser();
    } catch (error) {
      console.error("Error during push init:", error);
    }
  };

  subscribeUser = () => {
    navigator.serviceWorker.ready
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(this.vapidPublicKey),
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((pushSubscription) => {
        const key = pushSubscription.getKey("p256dh");
        const token = pushSubscription.getKey("auth");

        const data = {
          endpoint: pushSubscription.endpoint,
          key: key
            ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
            : null,
          token: token
            ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
            : null,
          content_encoding: (PushManager.supportedContentEncodings || [
            "aesgcm",
          ])[0],
        };

        console.log("Received PushSubscription: ", data);

        storePushSubscription(data);
      })
      .catch((error) => {
        console.error("Failed to subscribe the user: ", error);
      });
  };

  urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  };
}
