import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Page } from 'react-onsenui'
import { bindActionCreators } from 'redux';
import * as UserActions from '../actions/user';
import StarRating from './star-rating/StarRating';
import Loading from './Loading';
import ons from 'onsenui';
import { toastr } from 'react-redux-toastr';

function Feedback(props) {
  const { actions, user } = props;

  const [rating, setRating] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const textAreaRef = useRef();
  const isAndriod = ons.platform.isAndroid();

  const handleSubmit = () => {
    setSubmitting(true);

    return actions.submitFeedback(user.id, {
      'rating': rating,
      'feedback': textAreaRef.current ? textAreaRef.current.value : ''
    }).then(response => {
      setSubmitting(false);
      setRating(null);
      textAreaRef.current.value = '';

      toastr.success('Your rating has been submitted', 'Thank you for your feedback!', { timeOut: 3000 });
    });
  }

  return (
    <Page>
      <div className="c-feedback">
        <h1 className="u-text-center">We'd love to hear from you!</h1>
        <p>Leave us a star rating and an optional comment below:</p>

        {user ?
          <>
            <StarRating rating={rating} onChange={(newRating) => setRating(newRating)} />

            <div className="c-form__text-area">
              <textarea ref={textAreaRef} placeholder="Enter your comment here" rows={5}></textarea>
            </div>

            <div className="c-form__button-group">
              {submitting
                ? <Loading />
                : (
                  <div className="c-form__button-group">
                    <button
                      disabled={submitting || !rating}
                      type="button"
                      onClick={() => handleSubmit()}
                      className={isAndriod ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'}
                    >
                      Submit
                    </button>
                  </div>
                )}
            </div>
          </>
          : null}
      </div>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

