import React, { Component } from "react";
import { Page, BackButton, Toolbar, AlertDialog, Button } from "react-onsenui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../actions/user";
import RegisterForm from "./RegisterForm";
import ServiceWorker from "../../serviceWorker";
import { LOGIN } from "../../actionTypes";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationOpen: false,
      notificationTitle: "",
      notificationMessage: "",
    };
  }

  handleSubmit = (values) => {
    const { actions, navigator } = this.props;

    return actions
      .signup({
        ...values,
        lat: 0,
        lng: 0,
      })
      .then((response) => {
        console.log(response);

        actions
          .login({ email: values.email, password: values.password })
          .then((response) => {
            console.log(response);

            if (
              response.type === LOGIN &&
              response.payload.message !== "Authentication Failed"
            ) {
              actions.getUser().then((response) => {
                console.log(response);

                navigator.popPage();
              });

              new ServiceWorker().init();
            }
          });
      });
  };

  renderToolbar = () => {
    return (
      <Toolbar>
        <div className="left">
          <BackButton>Back</BackButton>
        </div>
        <div className="center">Quicky</div>
      </Toolbar>
    );
  };

  handleOkClicked = () => {
    this.setState({ notificationOpen: false });
  };

  render() {
    const { beans } = this.props;
    const { notificationOpen, notificationMessage, notificationTitle } =
      this.state;

    return (
      <>
        <Page className="register" renderToolbar={this.renderToolbar}>
          <RegisterForm onSubmit={this.handleSubmit} beans={beans} />
        </Page>

        <AlertDialog isOpen={notificationOpen} onCancel={this.handleOkClicked}>
          <div className="alert-dialog-title">{notificationTitle}</div>
          <div className="alert-dialog-content">{notificationMessage}</div>
          <div className="alert-dialog-footer">
            <Button
              onClick={this.handleOkClicked}
              className="alert-dialog-button"
            >
              Ok
            </Button>
          </div>
        </AlertDialog>
      </>
    );
  }
}

function mapSateToProps(state) {
  return {
    beans: state.beans,
    isAuthenticated: state.user.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch),
  };
}

export default connect(mapSateToProps, mapDispatchToProps)(Register);
