import React, { Component } from "react";
import {
  Page,
  Toolbar,
  BackButton,
  AlertDialog,
  Button,
  ProgressCircular,
  Card,
} from "react-onsenui";
import ResetPasswordForm from "./ResetPasswordForm";
import * as UserActions from "../../actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CREATE_PASSWORD_RESET, PASSWORD_RESET } from "../../actionTypes";
import RenderToolbar from "../RenderToolbar";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      title: "",
      content: "",
      sent: false,
      submitting: false,
    };
  }

  handleDialogClick = () => {
    const { sent } = this.state;
    const { navigator } = this.props;

    this.setState({ isOpen: false });

    if (sent) {
      navigator.popPage();
    }
  };

  handleSubmit = (values) => {
    this.setState({ submitting: true });

    const { actions } = this.props;

    actions.createPasswordReset({ email: values.email }).then((response) => {
      console.log(response);

      let title = "Email not found";
      let content =
        "We could not find an account with that email address in our database, please check the address you entered is correct and try again";
      let sent = false;

      if (response.type === CREATE_PASSWORD_RESET) {
        title = "Check your inbox";
        content =
          "An email has been sent to " +
          values.email +
          " please check your inbox";
        sent = true;
      }

      this.setState({
        isOpen: true,
        title: title,
        content: content,
        sent: sent,
        submitting: false,
      });
    });
  };

  renderToolbar = () => (
    <Toolbar>
      <div className="left">
        <BackButton>Back</BackButton>
      </div>
      <div className="center">Quicky</div>
    </Toolbar>
  );

  render() {
    const { isOpen, title, content, sent, submitting } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          <p>
            Please enter your e-mail address and we will send you a link with
            which you can reset your password
          </p>
          {submitting ? (
            <div className="u-text-center">
              <ProgressCircular indeterminate />
            </div>
          ) : (
            <ResetPasswordForm onSubmit={this.handleSubmit} />
          )}
        </Card>
        <AlertDialog isOpen={isOpen}>
          <div className="alert-dialog-title">{title}</div>
          <div className="alert-dialog-content">{content}</div>
          <div className="alert-dialog-footer">
            <Button
              onClick={() => this.handleDialogClick(sent)}
              className="alert-dialog-button"
            >
              Ok
            </Button>
          </div>
        </AlertDialog>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
