import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureAppStore from "../storeConfig";
import Main from "./Main";
import { authCheck, getUser, logout } from "../actions/user";
import { Navigator } from "react-onsenui";
import { AJAX_CALL_ERROR, AUTH_CHECK } from "../actionTypes";
import ServiceWorker from "../serviceWorker";
import ReduxToastr from "react-redux-toastr";

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "../sass/app.scss";

const store = configureAppStore();

const initializeApp = async () => {
  const authResponse = await store.dispatch(authCheck());

  if (authResponse.type === AUTH_CHECK && authResponse.payload) {
    const userResponse = await store.dispatch(getUser());

    if (userResponse.type === AJAX_CALL_ERROR) {
      store.dispatch(logout());
    }

    new ServiceWorker().init();
  }
};

initializeApp();

const App = () => (
  <Provider store={store}>
    <ReduxToastr
      position="top-center"
      showCloseButton
      closeOnToastrClick
      timeOut={0}
    />
    <Navigator
      renderPage={(route, navigator) => (
        <route.component navigator={navigator} />
      )}
      initialRoute={{ component: Main }}
      swipeable={true}
    />
  </Provider>
);

createRoot(document.getElementById("app")).render(<App />);
