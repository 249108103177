import axios from "axios";

const api = axios.create();

const hostname = process.env.REACT_APP_API;
const protocol =
  hostname.includes("local") || hostname === "127.0.0.1:8000"
    ? "http://"
    : "https://";

api.defaults.baseURL = `${protocol}${hostname}/api/`;
api.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
api.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// eslint-disable-next-line quotes
let csrfToken = document.head.querySelector('meta[name="csrf-token"]');

if (csrfToken) {
  api.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken.content;
} else {
  console.error(
    "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
  );
}

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers = config.headers || {};

      config.headers["Authorization"] = "Bearer " + token;
    } else {
      console.warn("Access token not found in localStorage");
    }

    return config;
  },
  function (error) {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

export default api;
