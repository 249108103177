import React from "react";
import { Card, Icon, Row, Col } from 'react-onsenui';

export default function PaymentMethodPreview({ paymentMethod }) {
  const isVisa = paymentMethod.card.brand.includes('visa');
  const isMasterCard = paymentMethod.card.brand.includes('mastercard');

  return (
    <div className="c-payment-method__item">
      <Card>
        <Row>
          <Col width="19%">
            <div className="c-payment-method__item-icon c-payment-method__item-icon--start">
              {isMasterCard ?
                <div className="c-payment-method__mastercard">
                  <img src="mastercard-logo.svg" />
                </div>
                : isVisa ?
                  <div className="c-payment-method__visa">
                    <img src="visa-logo.png" />
                  </div>
                  : <Icon
                    icon={{ default: 'ion-ios-card', material: 'ion-ios-card' }}
                    size={{ default: 40, material: 40 }}
                  />}
            </div>
          </Col>
          <Col width="60%" className="u-d-flex u-d-flex-col u-justify-content-center">
            <p className="u-mt-05 u-mb-0">{paymentMethod.billing_details.name}</p>
            <p className="u-mt-0 u-mb-05">{paymentMethod.card.brand.charAt(0).toUpperCase() + paymentMethod.card.brand.slice(1)} Card ending in {paymentMethod.card.last4}</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
}