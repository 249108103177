import Home from "../Home";
import Menu from "../Menu";
import Feedback from "../Feedback";
import QuickOrder from "../orders/QuickOrder";
import user from "../icons/user";
import home from "../icons/home";
import star from "../icons/star";
import chat from "../icons/chat";

const tabs = [
  {
    name: "menu",
    title: "Menu",
    icon: user,
    component: Menu,
  },
  {
    name: "home-page",
    title: "My Profile",
    icon: home,
    component: Home,
  },
  {
    name: "quick-order",
    title: "Quicky Order",
    icon: star,
    component: QuickOrder,
  },
  {
    name: "Feedback",
    title: "Feedback",
    icon: chat,
    component: Feedback,
  },
];

export default tabs;
