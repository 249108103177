/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Page } from "react-onsenui";
import CurrentOrder from "./CurrentOrder";
import RenderToolbar from "../RenderToolbar";
import SwipeableViews from "react-swipeable-views";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentOrders } from "../../reducers/user";
import { cancelOrder, updateOrderPickupTime } from "../../actions/user";
import { toastr } from "react-redux-toastr";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Directions from "../Directions";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

function CurrentOrders({ orders, popPage, pushPage }) {
  const dispatch = useDispatch();
  const currentOrders = useSelector(selectCurrentOrders);
  const dayOfWeek = dayjs().tz("Pacific/Auckland").format("dddd").toLowerCase();

  const [active, setActive] = useState(0);
  const [mode, setMode] = useState("carousel");
  // const [type, setType] = useState('brochure');
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderTime, setSelectedOrderTime] = useState(null);
  const [selectedOrderSaving, setSelectedOrderSaving] = useState(null);

  useEffect(() => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setPosition(pos);
          setLoading(false);
        },
        () => {
          console.log("Error: The Geolocation service failed.");
          setLoading(false);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log("Error: Your browser doesn't support geolocation.");
      setLoading(false);
    }
  }, []);

  const handleOrderClicked = (order) => {
    if (orders) {
      let idx = orders.findIndex((i) => i.id === order.id);

      setActive(idx);
      setMode("carousel");
    }
  };

  const handleEditTimeClicked = (order) => {
    const newSelectedOrder = currentOrders.find((i) => i.id === order.id);

    if (newSelectedOrder) {
      setSelectedOrder(newSelectedOrder);
      setSelectedOrderTime(dayjs.utc(newSelectedOrder.pickup_time).local());
    }
  };

  const handleOrderTimeUpdated = (selectedOrder, value) => {
    const newTime = value.utc().format("YYYY-MM-DD HH:mm:ss");
    setSelectedOrderSaving(selectedOrder.id);

    dispatch(
      updateOrderPickupTime(selectedOrder.id, { new_pickup_time: newTime })
    ).then((response) => {
      setSelectedOrderSaving(null);
    });

    handleTimePickerClosed();
  };

  const renderToolbar = () => (
    <RenderToolbar title="Quicky" backAction={popPage} />
  );

  const handleOrderCancelled = (id) => {
    dispatch(cancelOrder(id)).then((response) => {
      console.log(response);

      toastr.success("Order Cancelled", `Order ${id}`);

      popPage();
    });
  };

  const handleTimePickerClosed = () => {
    setSelectedOrder(null);
    setSelectedOrderTime(null);
  };

  const handleGetDirections = (shop) => {
    pushPage(Directions, "directions", {
      shop: shop,
      position: position,
    });
  };

  return (
    <Page renderToolbar={renderToolbar} className="page__current-orders">
      {mode === "stack" ? (
        <ul className="c-card-list">
          {currentOrders.map((order, i) => (
            <CurrentOrder
              key={i}
              order={order}
              loading={loading}
              onOrderClicked={handleOrderClicked}
              onOrderCancelled={handleOrderCancelled}
              onEditTimeClicked={handleEditTimeClicked}
              getDirections={position && handleGetDirections}
              saving={selectedOrderSaving === order.id}
            />
          ))}
        </ul>
      ) : (
        <SwipeableViews index={active}>
          {currentOrders &&
            currentOrders.map((order, i) => (
              <div key={`swipeable-${order.id}`} className="c-slick-card">
                <CurrentOrder
                  key={i}
                  order={order}
                  loading={loading}
                  onOrderClicked={handleOrderClicked}
                  onOrderCancelled={handleOrderCancelled}
                  onEditTimeClicked={handleEditTimeClicked}
                  getDirections={position && handleGetDirections}
                  saving={selectedOrderSaving === order.id}
                />
              </div>
            ))}
        </SwipeableViews>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          open={selectedOrder !== null}
          value={selectedOrderTime}
          onChange={(newTime) => setSelectedOrderTime(newTime)}
          minutesStep={5}
          minTime={dayjs().add(10, "minutes")}
          maxTime={
            selectedOrder &&
            selectedOrder.shop &&
            selectedOrder.shop[`closing_time_${dayOfWeek}`]
              ? dayjs(
                  selectedOrder.shop[`closing_time_${dayOfWeek}`],
                  "HH:mm:ss"
                )
              : dayjs().hour(17).minute(0)
          }
          renderInput={(params) => <div {...params} />}
          onAccept={(value) => handleOrderTimeUpdated(selectedOrder, value)}
          onClose={handleTimePickerClosed}
          ampmInClock={true}
        />
      </LocalizationProvider>
    </Page>
  );
}

export default CurrentOrders;
