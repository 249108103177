import React, { Component } from 'react';
import { Card } from 'react-onsenui';
import CafeList from './CafeList';
import { connect } from 'react-redux';
import OrderDrinkForm from '../drink/OrderDrinkForm';
import { bindActionCreators } from 'redux';
import * as ShopActions from '../../actions/shop';
import { Image } from 'cloudinary-react';

class FavourateCafes extends Component {
  
  handleFavourateCafeClicked = id => {
    const { pushPage, actions } = this.props;

    actions.setSelectedShop(id);

    pushPage(OrderDrinkForm, 'order-form', { shopId: id });
  }

  render() {
    const { user, view } = this.props;

    const userShops = user.shops.filter(s => s.active);

    return (
      <>
        {userShops.length > 0 && (
          <div className="c-favourate-cafes">
            {view === 'list' && <p className="c-favourate-cafes__title">My Favourite Cafes</p>}

            <div className="c-favourate-cafes__items">
              {userShops.map((shop, i) => (
                <Card key={i} className="c-favourate-cafes__items__item" onClick={() => this.handleFavourateCafeClicked(shop.id)}>
                  <div className="o-aspect-ratio">
                    <div className="o-aspect-ratio__content">
                      <Image cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={shop.banner} className="c-favourate-cafes__items__item__image" />
                      <p className="c-favourate-cafes__items__item__name">{shop.name}</p>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ShopActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavourateCafes);