import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import { ProgressCircular } from 'react-onsenui';
import ons from 'onsenui';

function ResetPasswordForm(props) {
  const { handleSubmit, submitting } = props;

  console.log('submitting props', submitting);

  const isAndroid = ons.platform.isAndroid();

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field 
          id="email"
          name="email"
          component={renderField} 
          type="text" 
          placeholder="Email"
          validate={[Validators.required, Validators.email]}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <button type="submit" className={isAndroid ? 'button--material button--large--cta u-mt-1' : 'button--large--cta u-mt-1'}>
          Send
        </button>
      </div>
    </form>
  ); 
}

export default reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm)