/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { ProgressCircular, Row, Col, Icon } from "react-onsenui";
import * as dayjs from "dayjs";
import Loading from "../Loading";
import { Button } from "reactstrap";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export default function CurrentOrder({
  loading,
  order,
  onOrderClicked,
  onOrderCancelled,
  onEditTimeClicked,
  getDirections,
  saving,
}) {
  const [percentage, setPercentage] = useState(0);
  const [countdownHours, setCountdownHours] = useState("");
  const [countdownMins, setCountdownMins] = useState("");
  const [countdownSecs, setCountdownSecs] = useState("");
  const [ready, setReady] = useState(false);
  const [active, setActive] = useState(false);

  const dtOrder = dayjs
    .utc(order.created_at)
    .local()
    .format("DD/MM/YYYY h:mma");
  const dtPickup = dayjs.utc(order.pickup_time).local();
  const dtStart = dayjs();

  const fullDuration = dayjs.duration(dtPickup.diff(dtStart)).asSeconds();
  const [editable, setEditable] = useState(
    order.order_status_id < 4 && fullDuration > 300
  );

  const handleEditTimeClicked = (e, order) => {
    e.preventDefault();
    onEditTimeClicked(order);
  };

  useEffect(() => {
    if (order.order_status_id < 4) {
      if (fullDuration > 0) {
        setPercentage(0);

        const interval = setInterval(() => {
          const dtNow = dayjs();
          const duration = dayjs.duration(dtPickup.diff(dtNow));
          const hours = parseInt(duration.format("H"));

          setCountdownHours(hours);
          setCountdownMins(
            hours > 0
              ? duration.format("mm")
              : hours * 60 + parseInt(duration.format("m"))
          );
          setCountdownSecs(duration.format("ss"));

          setPercentage((percentage) => {
            const p = percentage + 100 / fullDuration;

            if (p > 100) {
              setReady(true);
              setPercentage(100);
              clearInterval(interval);
            }

            return p;
          });

          if (duration.asSeconds() < 300 && editable) setEditable(false);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        setReady(true);
        setPercentage(100);
        if (editable) setEditable(false);
      }
    }
  }, [order.pickup_time]);

  const pluralDrinks = order.products.length > 1;

  return (
    <li className={"c-card" + (active ? " c-card--active" : "")}>
      <div
        className="c-card__header"
        onClick={() => (onOrderClicked ? onOrderClicked(order) : false)}
      >
        {/* <h2 className="u-text-center u-text-white u-mt-0">Order 2022{order.id}</h2> */}
        <h1 className="u-text-white u-mt-0 u-mb-0">Right oh,</h1>

        {order.order_status_id === 1 ? (
          <h2 className="u-text-white">Your order is pending</h2>
        ) : order.order_status_id === 2 ? (
          <h2 className="u-text-white">Your order is in queue</h2>
        ) : order.order_status_id === 3 ? (
          <h2 className="u-text-white">
            Your {pluralDrinks ? "drinks are" : "drink is"} being prepared
          </h2>
        ) : order.order_status_id === 4 ? (
          <h2 className="u-text-white">
            Your {pluralDrinks ? "drinks are" : "drink is"} ready to pick up
          </h2>
        ) : (
          <h2 className="u-text-white">
            Your {pluralDrinks ? "drinks" : "drink"} will be ready in
          </h2>
        )}
      </div>

      <div className="c-current-order__items">
        {order.order_status_id === 1 ? (
          editable ? (
            <>
              {saving ? (
                <Icon
                  size={{ default: 18, material: 18 }}
                  icon="md-refresh-sync"
                  spin={true}
                  className="u-mb-2"
                />
              ) : (
                <a
                  className="u-m-1 c-btn button"
                  href="#"
                  onClick={(e) => handleEditTimeClicked(e, order)}
                >
                  Change pickup time
                  <Icon
                    className="u-ml-05"
                    size={{ default: 18, material: 18 }}
                    icon={{ default: "md-edit" }}
                  />
                </a>
              )}
            </>
          ) : (
            <br />
          )
        ) : order.order_status_id === 2 ||
          order.order_status_id === 3 ||
          order.order_status_id === 4 ? (
          <>
            {/* <h3 className="c-timer__heading">Estimated time until ready:</h3> */}

            <div className="c-timer">
              <ProgressCircular value={percentage} />

              {editable && !saving ? (
                <div
                  className="c-timer__ready"
                  onClick={() => onEditTimeClicked(order)}
                >
                  {dtPickup.format("h:mma")}

                  <Icon
                    className="u-ml-05"
                    size={{ default: 18, material: 18 }}
                    icon="md-edit"
                  />
                </div>
              ) : (
                <div className="c-timer__ready">
                  {dtPickup.format("h:mma")}

                  {saving && (
                    <Icon
                      className="u-ml-05"
                      size={{ default: 18, material: 18 }}
                      icon="md-refresh-sync"
                      spin={true}
                    />
                  )}
                </div>
              )}

              <div className="c-timer__countdown">
                {ready || order.order_status_id === 4 ? (
                  <span className="c-timer__mins">Ready</span>
                ) : countdownHours > 0 ? (
                  <>
                    <span className="c-timer__mins">{countdownHours}</span> hr{" "}
                    <span className="c-timer__secs">{countdownMins}</span> min
                  </>
                ) : (
                  <>
                    <span className="c-timer__mins">{countdownMins}</span> min{" "}
                    <span className="c-timer__secs">{countdownSecs}</span> sec
                  </>
                )}
              </div>
            </div>
          </>
        ) : null}

        <p className="c-current-order__order-no">Order: {order.id}</p>

        {order.order_status_id < 4 ? (
          <>
            <p className="c-current-order__items-cafe">
              Queued at {order.shop.name}
            </p>
            <p className="c-current-order__items-time u-mb-0">
              <small>Joined queue at {dtOrder}</small>
            </p>
            <p className="c-current-order__items-time">
              <small>Expected pickup time {dtPickup.format("h:mma")}</small>
            </p>
          </>
        ) : null}

        {order.products.map((p) => (
          <div key={`product-${p.id}`} className="c-current-order__item">
            <p key={p.id} className="u-text-center">
              1 x {p.product.name}
            </p>

            <ul>
              {p.variations.map((v, i) => (
                <li key={`variation${v.id}`} className="u-text-center">
                  {v.variation.name}
                </li>
              ))}

              {p.extras &&
                p.extras.length > 0 &&
                p.extras
                  .map((e) => {
                    // How many duplicates of this extra do we have?
                    const extrasCount = p.extras.filter(
                      (pExtra) => pExtra.extra.id === e.extra.id
                    ).length;
                    return extrasCount > 1
                      ? `${extrasCount} x ${e.extra.name}`
                      : e.extra.name;
                  })
                  .reduce(
                    (unique, extra) =>
                      unique.includes(extra) ? unique : [...unique, extra],
                    []
                  )
                  .map((extraText, i) => (
                    <li
                      key={`extra-${i}`}
                      className="c-cart__product__details__extra u-text-center"
                    >
                      {extraText}
                    </li>
                  ))}

              {p.notes ? (
                <li className="u-text-italic u-text-center u-mt-05">
                  Notes: {p.notes}
                </li>
              ) : null}
            </ul>
          </div>
        ))}
      </div>

      <div className="c-current-order-directions">
        {order.order_status_id < 3 ? (
          <a
            href="#"
            className="c-btn button u-mr-1"
            onClick={(e) =>
              window.confirm("Are you sure?")
                ? onOrderCancelled(order.id)
                : false
            }
          >
            Cancel Order
          </a>
        ) : null}

        {loading ? (
          <div className="u-minwidth-7">
            <Loading />
          </div>
        ) : getDirections ? (
          <Button
            onClick={() => getDirections(order.shop)}
            className="c-btn button white"
          >
            Get Directions
          </Button>
        ) : null}
      </div>
    </li>
  );
}
