/* eslint-disable no-undef */
/* eslint-disable no-class-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { reduxForm, change, Form, formValueSelector } from "redux-form";
import { DrinkStep, DrinkSizeStep, DrinkExtrasStep } from "./steps";
import { connect } from "react-redux";
import {
  selectIncrementableExtras,
  selectNonIncrementableExtras,
  selectProduct,
  selectProducts,
  selectShop,
  selectSizeVariations,
  selectStrengthVariations,
  selectBeanVariations,
  selectMilkVariations,
} from "../../reducers/shops";
import { Page, Toolbar, BackButton, Button, Modal, Icon } from "react-onsenui";
import * as ShopActions from "../../actions/shop";
import * as UserActions from "../../actions/user";
import Cart from "../cart/Cart";
import Loading from "../Loading";
import ons from "onsenui";
import AppIcon from "@material-ui/icons/Apps";
import BurgerIcon from "@material-ui/icons/ViewHeadline";
import { debounce } from "lodash";

class OrderDrinkForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      submitting: false,
      gridViewSelected: props.user ? props.user.tileview_preferred : false,
      noteModalOpen: false,
      notes: "",
      savedNotes: "",
    };

    this.pageRef = React.createRef();

    this.handleBackPressed = debounce(this.handleBackPressed.bind(this), 300);
  }

  handleBackPressed = () => {
    const { popPage } = this.props;

    this.setState({ step: 0 });
    popPage();
  };

  renderToolbar = () => {
    const { pushPage, user } = this.props;

    return (
      <Toolbar position="top">
        <div className="left">
          <BackButton onClick={this.handleBackPressed}>Back</BackButton>
        </div>
        <div className="center">
          <div className="c-navbar__content">
            {process.env.REACT_APP_ENVIRONMENT &&
            process.env.REACT_APP_ENVIRONMENT === "staging" ? (
              <h1>Quicky Staging</h1>
            ) : (
              <img
                alt="Quicky"
                className="c-navbar__logo u-mr-05"
                src="/images/logo.png"
              />
            )}
          </div>
        </div>
        <div className="right">
          <p className="c-button__icon-num">{user.cartItems.length}</p>
          <Button
            className="cart-button"
            modifier="material--flat"
            onClick={() => pushPage(Cart)}
          >
            <svg
              version="1.1"
              id="svg__cup-with-steam"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 135 234.2"
            >
              <g id="cup">
                <polygon
                  className="st0"
                  points="21.6,228.8 4.6,81.8 129.4,81.8 112.4,228.8"
                />
                <path d="M128.9,82.3l-16.9,146H22L5.1,82.3H128.9 M130,81.3H4l17.1,148h91.8L130,81.3L130,81.3z" />
              </g>
              <g id="steam">
                <path
                  className="st1"
                  d="M51.6,5.4c0,0-8.4,12.1-2.3,23.4c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"
                />
                <path
                  className="st1"
                  d="M63,5.4c0,0-8.4,12.1-2.3,23.5c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"
                />
                <path
                  className="st1"
                  d="M74.5,5.4c0,0-8.4,12.2-2.3,23.6c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.8-2,23.8"
                />
              </g>
              <g id="liquid_half">
                <polygon
                  className="st2"
                  points="112.1,228.3 21.9,228.3 13.9,158.3 120.1,158.3"
                />
              </g>
              <g id="liquid_almost">
                <polygon
                  className="st2"
                  points="112.1,228.3 21.9,228.3 8.8,114.3 125.2,114.3"
                />
              </g>
            </svg>
          </Button>
        </div>
      </Toolbar>
    );
  };

  handleDrinkSelected = (value) => {
    const { actions, cafe } = this.props;

    actions.change("OrderDrinkForm", "product_id", value);
    actions.change("OrderDrinkForm", "shop_id", cafe.id);
    actions.change("OrderDrinkForm", "company_id", cafe.company_id);

    actions.setSelectedProduct(value);

    this.setState({ step: 1 });

    this.pageRef.current.scrollIntoView({ behavior: "smooth" });
  };

  handleDrinkUnselected = () => {
    this.setState({ step: 0 });
  };

  handleSizeSelected = (size) => {
    const { actions } = this.props;

    actions.change("OrderDrinkForm", "size", size);

    this.setState({ step: 2 });
  };

  handleExtrasIncrement = (eId) => {
    const { actions, extraVals } = this.props;

    actions.change("OrderDrinkForm", "extras", [...extraVals, eId]);
  };

  handleExtrasDecrement = (eId) => {
    const { actions, extraVals } = this.props;

    let newExtraVals = [...extraVals];

    let index = newExtraVals.lastIndexOf(eId);
    if (index !== -1) {
      newExtraVals.splice(index, 1);
    }

    actions.change("OrderDrinkForm", "extras", newExtraVals);
  };

  clearFormValues = () => {
    const { actions } = this.props;

    actions.change("OrderDrinkForm", "product_id", 0);
    actions.change("OrderDrinkForm", "company_id", 0);
    actions.change("OrderDrinkForm", "shop_id", 0);
    actions.change("OrderDrinkForm", "beans", 0);
    actions.change("OrderDrinkForm", "size", 0);
    actions.change("OrderDrinkForm", "milk", 0);
    actions.change("OrderDrinkForm", "strength", 0);
    actions.change("OrderDrinkForm", "extras", []);

    this.setState({ notes: "", savedNotes: "" });
  };

  handleCancel = () => {
    this.clearFormValues();

    this.setState({ step: 0 });
  };

  handleAddToCart = async (values) => {
    const { actions, product, user, extraVals, strengthVariations } =
      this.props;

    let cartItem = {
      ...product,
      product_id: values.product_id,
      company_id: values.company_id,
      shop_id: values.shop_id,
      number: 1,
      variations: {
        1: values.size,
      },
      extras: extraVals,
      notes: values.notes,
    };

    cartItem.variations[2] = values.milk ? values.milk : 0;
    cartItem.variations[3] = values.beans ? values.beans : 0;
    cartItem.variations[4] = strengthVariations.length
      ? strengthVariations[values.strength]
        ? strengthVariations[values.strength].id
        : strengthVariations[1]
        ? strengthVariations[1].id
        : 0
      : 0;

    console.log("cart item", cartItem);

    actions.addCartItem(cartItem);

    // Does our user have this shop yet?
    if (user.shops.filter((s) => s.id === values.shop_id).length <= 0) {
      actions
        .addUserShop(values.shop_id)
        .then((response) => console.log(response));
    }
  };

  handleFormSubmitAndOrderAgain = (values) => {
    const { savedNotes } = this.state;

    return this.handleAddToCart({ ...values, notes: savedNotes }).then(() => {
      this.setState({ step: 0 });
      this.clearFormValues();
      this.pageRef.current.scrollIntoView({ behavior: "smooth" });
    });
  };

  handleFormSubmitAndProceed = (values) => {
    console.log("Submitting form and proceeding to cart...");
    const { pushPage, user } = this.props;
    const { savedNotes } = this.state;

    this.setState({ submitting: true }); // Disable buttons during submission to prevent double clicks

    // Ensure the cart page is shown after form submission
    return this.handleAddToCart({ ...values, notes: savedNotes })
      .then(() => {
        console.log("Redirecting to cart page...");
        // Push the cart page before resetting the state
        pushPage(Cart, `cart-${user.cartItems.length}`);
        this.setState({ step: 0 });
        this.clearFormValues();
      })
      .catch((err) => {
        console.error("Error while submitting the form:", err);
        this.setState({ submitting: false }); // Re-enable buttons if an error occurs
      });
  };

  handleViewToggle = () => {
    const { actions, user } = this.props;

    this.setState({ gridViewSelected: !this.state.gridViewSelected });

    actions.updateTileviewPreferred(user.id);
  };

  render() {
    const { step, gridViewSelected, noteModalOpen, notes, savedNotes } =
      this.state;
    const {
      cafe,
      handleSubmit,
      milkVal,
      beansVal,
      extraVals,
      milkVariations,
      beanVariations,
      products,
      sizeVariations,
      incrementableExtras,
      nonIncrementableExtras,
      productVal,
      sizeVal,
      submitting,
      strengthVariations,
    } = this.props;

    const isAndroid = ons.platform.isAndroid();
    const disabled =
      !sizeVal ||
      (milkVariations.length > 0 && (!milkVal || milkVal === 0)) ||
      (beanVariations.length > 0 && (!beansVal || beansVal === 0));

    return (
      <Page
        renderToolbar={this.renderToolbar}
        className={(step > 0 ? `step-2` : `step-0`) + " drink-form"}
      >
        <Button
          className={
            (gridViewSelected ? `c-drink__list ` : `c-drink__grid `) +
            (step > 0 && `c-btn__hide`) +
            ` c-btn__toggle-layout`
          }
          modifier="material--flat"
          onClick={this.handleViewToggle}
        >
          {gridViewSelected ? <BurgerIcon /> : <AppIcon />}
        </Button>

        <Form
          className="c-form c-form--order"
          onSubmit={handleSubmit(this.handleFormSubmitAndOrderAgain)}
        >
          <div className="c-text__header" ref={this.pageRef}>
            <h1 className="bold">{cafe.name}</h1>

            <h2>Good call.</h2>
            <h2>What do you fancy?</h2>
          </div>

          <div className={gridViewSelected ? `c-drink__grid` : `c-drink__list`}>
            <DrinkStep
              onDrinkSelected={this.handleDrinkSelected}
              onDrinkUnselected={this.handleDrinkUnselected}
              products={products}
              selectedProduct={productVal}
            />
          </div>

          {step > 0 && (
            <>
              <DrinkSizeStep
                onSizeSelected={this.handleSizeSelected}
                sizeVariations={sizeVariations}
                selectedSize={sizeVal}
              />

              <DrinkExtrasStep
                beanVariations={beanVariations}
                milkVariations={milkVariations}
                incrementableExtras={incrementableExtras}
                nonIncrementableExtras={nonIncrementableExtras}
                onExtrasIncrement={this.handleExtrasIncrement}
                onExtrasDecrement={this.handleExtrasDecrement}
                extraVals={extraVals}
                strengthVariations={strengthVariations}
              />

              <div className="u-text-center">
                <Button
                  className="c-btn c-btn__text"
                  onClick={(e) => this.setState({ noteModalOpen: true })}
                >
                  Add note
                </Button>

                <Modal isOpen={noteModalOpen}>
                  <div className="c-modal">
                    <p>Add note</p>

                    <textarea
                      name="notes"
                      value={notes}
                      onChange={(e) => this.setState({ notes: e.target.value })}
                      className="u-mb-1"
                    />

                    <div className="u-text-center">
                      <Button
                        className="c-btn white u-mr-1"
                        onClick={(e) =>
                          this.setState({
                            noteModalOpen: false,
                            notes: savedNotes === "" ? "" : savedNotes,
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        className="c-btn"
                        onClick={(e) =>
                          this.setState({
                            noteModalOpen: false,
                            savedNotes: notes,
                          })
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>

              <div className="c-drink__add-to-cart">
                {submitting ? (
                  <Loading />
                ) : (
                  <>
                    <div>
                      <Button
                        className="c-btn c-btn__reset c-btn white"
                        onClick={this.handleCancel}
                      >
                        Reset
                      </Button>

                      <button
                        type="submit"
                        disabled={disabled}
                        className={
                          isAndroid
                            ? "c-btn c-btn__next button button--material"
                            : "c-btn__next button"
                        }
                      >
                        <span>Order another drink</span>
                      </button>
                    </div>

                    <div>or</div>

                    <div className="u-mt-05">
                      <Button
                        className="c-btn gold"
                        onClick={handleSubmit(this.handleFormSubmitAndProceed)}
                        disabled={disabled}
                      >
                        <span>Proceed to checkout</span>

                        <Icon
                          className="u-ml-05"
                          icon={{
                            default: "ion-ios-cart",
                            material: "ion-ios-cart",
                          }}
                          size={{ default: 25, material: 25 }}
                        />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Form>
      </Page>
    );
  }
}

OrderDrinkForm = reduxForm({
  form: "OrderDrinkForm",
  initialValues: {
    extras: [],
    proceed_to_checkout: false,
    strength: 0,
  },
})(OrderDrinkForm);

const selector = formValueSelector("OrderDrinkForm");

function mapStateToProps(state) {
  const incrementableExtras = selectIncrementableExtras(state);
  const nonIncrementableExtras = selectNonIncrementableExtras(state);

  return {
    user: state.user,
    cafe: selectShop(state),
    products: selectProducts(state),
    product: selectProduct(state),
    productVal: selector(state, "product_id"),
    beanVariations: selectBeanVariations(state),
    milkVariations: selectMilkVariations(state),
    sizeVariations: selectSizeVariations(state),
    strengthVariations: selectStrengthVariations(state),
    sizeVal: selector(state, "size"),
    strengthVal: selector(state, "strength"),
    milkVal: selector(state, "milk"),
    beansVal: selector(state, "beans"),
    extraVals: selector(state, "extras"),
    incrementableExtras,
    nonIncrementableExtras,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...ShopActions, ...UserActions, change },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDrinkForm);
