/* eslint-disable no-undef */
import React, { Component } from "react";
import {
  Page,
  Tabbar,
  Tab,
  Toolbar,
  Icon,
  Dialog,
  Button,
  ActionSheet,
} from "react-onsenui";
import tabs from "./tabs";
import Navigator from "../Navigator";

class TabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      installMessageType: "",
      showNotificationPrompt: false,
      active: 1,
      tabStacks: [{ size: 0 }, { size: 0 }, { size: 0 }, { size: 0 }],
    };
  }

  componentDidMount() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = !isIos && userAgent.indexOf("android") > -1;

    const isIosStandaloneMode =
      "standalone" in window.navigator && window.navigator.standalone;
    const isAndroidStandaloneMode =
      !isIos && window.matchMedia("(display-mode: standalone)").matches;

    if (isIos) {
      if (isIosStandaloneMode && window.Notification.permission === "default") {
        this.setState({ showNotificationPrompt: true });
      } else if (!isIosStandaloneMode) {
        this.setState({ installMessageType: "ios" });
      }
    } else if (isAndroid && !isAndroidStandaloneMode) {
      this.setState({ installMessageType: "android" });
    }
  }

  setActiveTab = (tabIndex) => {
    this.setState({ active: tabIndex });
  };

  renderToolbar = () => {
    const { active, tabStacks } = this.state;

    return (
      tabStacks[active].size <= 1 && (
        <Toolbar
          position="top"
          className={active === 0 ? "toolbar__dark" : "toolbar__light"}
        >
          <div className="">
            <div className={`c-navbar__content c-navbar__content--${active}`}>
              {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                <h1 className="u-ml-1 u-text-black">Quicky Staging</h1>
              ) : (
                <>
                  <img
                    alt="Quicky"
                    className="c-navbar__logo u-mr-04"
                    src={`/images/logo${active === 0 ? "_white" : ""}.png`}
                  />
                  <h1
                    className={`u-m-0 u-text-large${
                      active > 0 ? " u-text-black" : ""
                    }`}
                  >
                    BETA
                  </h1>
                </>
              )}
            </div>
          </div>
        </Toolbar>
      )
    );
  };

  handleStackChange = (stackSize) => {
    const { active, tabStacks } = this.state;
    const newTabStacks = tabStacks.map((stack, index) =>
      index === active ? { size: stackSize } : stack
    );

    this.setState({ tabStacks: newTabStacks });
  };

  renderTabs = () => {
    return tabs.map((tab, i) => ({
      content: (
        <Navigator
          tab={tab}
          key={`content${i}`}
          onStackChange={this.handleStackChange}
          swipeable={true}
          setActiveTab={this.setActiveTab}
        />
      ),
      tab: (
        <Tab key={`tab${i}`}>
          {tab.iconPath ? (
            <img src={tab.iconPath} alt={tab.name} />
          ) : (
            tab.icon()
          )}
        </Tab>
      ),
    }));
  };

  render() {
    const { installMessageType, showNotificationPrompt } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Dialog isOpen={showNotificationPrompt}>
          <div className="c-modal--notification-dialog">
            <Icon
              className="c-modal--notification-dialog__close"
              size={{ default: 26, material: 26 }}
              icon={{ default: "ion-ios-close", material: "md-close" }}
              onClick={() => this.setState({ showNotificationPrompt: false })}
            />

            <img
              src="/images/logo.png"
              className="c-modal--notification-dialog__allow-notifications"
              alt="App logo"
            />

            <p>Allow notifications from Quicky?</p>

            <div>
              <Button
                id="allow_notifications_btn"
                onClick={() => this.setState({ showNotificationPrompt: false })}
              >
                Allow
              </Button>
              <Button
                className="u-ml-1"
                onClick={() => this.setState({ showNotificationPrompt: false })}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>

        <ActionSheet
          isOpen={installMessageType !== ""}
          isCancelable={true}
          maskColor="rgba(0, 0, 0, 0.75)"
        >
          <div className="c-add-to-home">
            <div className="u-d-flex u-align-items-center">
              <img
                src="/app-icon.jpg"
                className="c-add-to-home__logo"
                alt="App logo"
              />
              <h2>Install Quicky</h2>
            </div>

            <hr />

            <div className="c-add-to-home__content">
              {installMessageType === "android" ? (
                <>
                  <p className="u-mb-1">
                    To get started, follow the steps to install me as an app on
                    your Home Screen.
                  </p>
                  <div className="u-d-flex u-align-items-center u-mb-1">
                    1. In the top right corner tap on
                    <img
                      src="/ellipsis-vertical.svg"
                      className="c-add-to-home__ellipsis"
                      alt="Ellipsis vertical"
                    />
                  </div>
                  <div className="u-d-flex u-align-items-center u-mb-1">
                    2. Scroll to <strong>Install</strong>
                  </div>
                </>
              ) : (
                <>
                  <p className="u-mb-1">
                    To get started, follow the steps to install me as an app on
                    your Home Screen.
                  </p>
                  <div className="u-d-flex u-align-items-center u-mb-1">
                    1. In the bottom bar tap on
                    <img
                      src="/share.svg"
                      className="c-add-to-home__share"
                      alt="Share icon"
                    />
                  </div>
                  <div className="u-d-flex u-align-items-center u-mb-1">
                    2. Scroll to <strong>Add to Home Screen</strong>
                  </div>
                </>
              )}
            </div>

            <div className="c-add-to-home__btn-container">
              <Button onClick={() => this.setState({ installMessageType: "" })}>
                Maybe later
              </Button>
            </div>
          </div>
        </ActionSheet>

        <Tabbar
          renderTabs={this.renderTabs}
          onPreChange={({ index }) => this.setState({ active: index })}
          swipeable
        />
      </Page>
    );
  }
}

export default TabBar;
