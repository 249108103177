// User
export const AUTH_CHECK = "AUTH_CHECK";
export const GET_AUTH_USER = "GET_AUTH_USER";
export const GET_USER = "GET_USER";
export const SAVE_USER = "SAVE_USER";
export const CREATE_PASSWORD_RESET = "CREATE_PASSWORD_RESET";
export const FIND_PASSWORD_RESET = "FIND_PASSWORD_RESET";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const ADD_USER_SHOP = "ADD_USER_SHOP";
export const REMOVE_USER_SHOP = "REMOVE_USER_SHOP";
export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_CART_ITEM_VARIATION = "UPDATE_CART_ITEM_VARIATION";
export const UPDATE_CART_ITEM_EXTRAS = "UPDATE_CART_ITEM_EXTRAS";
export const UPDATE_CART_ITEM_NOTES = "UPDATE_CART_ITEM_NOTES";
export const UPDATE_PICKUP_TIME = "UPDATE_PICKUP_TIME";
export const UPDATE_ORDER_PICKUP_TIME = "UPDATE_ORDER_PICKUP_TIME";
export const DELAY_ORDER_PICKUP_TIME = "DELAY_ORDER_PICKUP_TIME";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const PURCHASE = "PURCHASE";
export const UPDATE_USER_BEAN_PREFERENCES = "UPDATE_USER_BEAN_PREFERENCES";
export const UPDATE_TILEVIEW_PREFERRED = "UPDATE_TILEVIEW_PREFERRED";
export const ADD_USER_PAYMENT_METHOD = "ADD_USER_PAYMENT_METHOD";
export const GET_USER_PAYMENT_METHODS = "GET_USER_PAYMENT_METHODS";
export const DESTROY_USER_PAYMENT_METHOD = "DESTROY_USER_PAYMENT_METHOD";
export const UPDATE_USER_DEFAULT_PAYMENT_METHOD =
  "UPDATE_USER_DEFAULT_PAYMENT_METHOD";
export const UPDATE_USER_ORDER_STATUS = "UPDATE_USER_ORDER_STATUS";
export const SIGNUP_ACTIVATE = "SIGNUP_ACTIVATE";
export const SEND_HELP_EMAIL = "SEND_HELP_EMAIL";
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";

// Order
export const REDEEM_ORDER_PRODUCT = "REDEEM_ORDER_PRODUCT";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const CANCEL_ORDER = "CANCEL_ORDER";

// Login
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";

// Shops
export const GET_SHOPS = "GET_SHOPS";
export const SET_SHOPS_TERM = "GET_SHOPS_TERM";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_SHOPS_SEARCH_TERM = "SET_SHOPS_SEARCH_TERM";
export const SET_SHOPS_SELECTED_SHOP = "SET_SHOPS_SELECTED_SHOP";
export const SET_SHOPS_SELECTED_PRODUCT = "SET_SHOPS_SELECTED_PRODUCT";
export const GET_DISCOUNT_BY_CODE = "GET_DISCOUNT_BY_CODE";

// Companies
export const GET_COMPANY = "GET_COMPANY";

// Beans
export const GET_BEANS = "GET_BEANS";

// Error
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
