import React, { Component } from 'react';
import CafeList from './CafeList';
import Map from '../Map';
import { Button, Icon } from 'react-onsenui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ShopActions from '../../actions/shop';
import { selectShopsBySearchTerm } from '../../reducers/shops';
import { selectUserBeanIds } from '../../reducers/user';

class FindCafes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: null,
      positionAttempted: false
    };
  }

  componentDidMount() {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.setState({ position: pos, positionAttempted: true });
      }, () => {
        console.log('Error: The Geolocation service failed.')
        this.setState({ positionAttempted: true });
      });
    } else {
      // Browser doesn't support Geolocation
      console.log('Error: Your browser doesn\'t support geolocation.');
      this.setState({ positionAttempted: true });
    }
  }

  render() {
    const { position, positionAttempted } = this.state;
    const { pushPage, shops, actions, view, onViewChange, userBeanIds } = this.props;

    return (
      <>
        {view === 'list' ?
          <CafeList 
            pushPage={pushPage}
            position={position}
            positionAttempted={positionAttempted}
          />
        : view === 'map' ?
          <Map
            userBeanIds={userBeanIds}
            markers={shops}
            setSelectedShop={actions.setSelectedShop}
            pushPage={pushPage}
            position={position}
          />
        : null}

        <div className="view-toggle-container">
          <Button modifier="cta view-toggle" onClick={onViewChange}>
            {view === 'list' 
              ? <Icon icon={{default: 'ion-ios-map', material: 'md-map'}} size={{default: 24, material: 24}} /> 
              : <Icon icon={{default: 'ion-ios-list', material: 'ion-ios-list'}} size={{default: 24, material: 24}} />
            }
          </Button>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    shops: selectShopsBySearchTerm(state),
    userBeanIds: selectUserBeanIds(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ShopActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCafes)