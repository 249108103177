import React, { useEffect } from "react";
import { connect } from "react-redux";
import SignupActivate from "./login/SignupActivate";
import Login from "./login/Login";
import ResetPasswordConfirm from "./login/ResetPasswordConfirm";
import TabBar from "./tabs/TabBar";

function Main(props) {
  const { isAuthenticated, navigator } = props;

  useEffect(() => {
    if (isAuthenticated) {
      navigator.resetPage({ component: TabBar }, { animation: "fade" });
    }
  }, [isAuthenticated, navigator]);

  if (window.location.pathname.includes("signupActivate")) {
    return <SignupActivate navigator={navigator} />;
  } else if (window.location.pathname.includes("passwordReset")) {
    return <ResetPasswordConfirm navigator={navigator} />;
  } else {
    return isAuthenticated ? (
      <TabBar navigator={navigator} />
    ) : (
      <Login navigator={navigator} />
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.loggedIn,
});

export default connect(mapStateToProps)(Main);
