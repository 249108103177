import React from 'react';
import { List, ListItem, Card, Icon, Row, Col, Button } from 'react-onsenui';


function PaymentMethodsList({
  paymentMethods,
  onPaymentMethodClicked,
  selectedPaymentMethod,
  onPaymentMethodRemoveClicked,
  showRemove,
  showSetDefault,
  onDefaultPaymentMethodClicked,
  defaultPaymentMethodId = null
}) {
  const handleItemClicked = paymentMethod => {
    if (onPaymentMethodClicked) {
      onPaymentMethodClicked(paymentMethod);
    }
  }

  const renderRow = (item, i) => {
    const isDefault = defaultPaymentMethodId === item.id;
    const isVisa = item.card.brand.includes('visa');
    const isMasterCard = item.card.brand.includes('mastercard');
    
    return (
      <ListItem
        key={i}
        className={'c-payment-method__item' + (selectedPaymentMethod && selectedPaymentMethod.id === item.id ? ' c-payment-method__item--selected' : '')}
        onClick={() => handleItemClicked(item)}
      >
        <Card>
          <Row className="u-mt-1 u-mb-1">
            {showSetDefault && (
              <Col width="15%">
                <div className="c-payment-method__item-icon c-payment-method__item-icon--start">
                  <button type="button" className="c-payment-method__item-remove" onClick={() => onDefaultPaymentMethodClicked(item)}>
                    <Icon
                      icon={{ default: 'ion-ios-star', material: 'ion-ios-star' }}
                      size={{ default: 30, material: 30 }}
                      style={{ color: isDefault ? '#FFD700' : '#69696950' }}
                    />
                  </button>
                </div>
              </Col>
            )}
            <Col width="15%">
              <div className="c-payment-method__item-icon c-payment-method__item-icon--start">
                {isMasterCard ?
                  <div className="c-payment-method__mastercard">
                    <img src="mastercard-logo.svg" />
                  </div>
                  : isVisa ?
                    <div className="c-payment-method__visa">
                      <img src="visa-logo.png" />
                    </div>
                    : <Icon
                      icon={{ default: 'ion-ios-card', material: 'ion-ios-card' }}
                      size={{ default: 40, material: 40 }}
                    />}
              </div>
            </Col>
            <Col width="50%" className="u-d-flex u-d-flex-col u-justify-content-center">
              <p className="u-mt-0 u-mb-0 u-text-strong">{item.billing_details.name}</p>
              <p className="u-mt-0 u-mb-0"><span className="u-capitalize">{item.card.brand}</span> Card ending in {item.card.last4}</p>
            </Col>
            {showRemove && (
              <Col width="15%">
                <div className="c-payment-method__item-icon c-payment-method__item-icon--end">
                  <Button
                    onClick={() => onPaymentMethodRemoveClicked(item)}
                    className="c-payment-method__item-remove"
                  >
                    <Icon
                      icon={{ default: 'ion-ios-trash', material: 'ion-ios-trash' }}
                      size={{ default: 30, material: 30 }}
                    />
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          {/* <Row>
            <a className="c-payment-method__stripe-wordmark" href="https://stripe.com/nz/legal/consumer" target="_blank" rel="noreferrer">
              <img src="stripe-wordmark-purple.svg" />

              <Icon
                icon={{ default: 'ion-ios-open', material: 'ion-ios-open' }}
                size={{ default: 20, material: 20 }}
              />
            </a>
          </Row> */}
        </Card>
      </ListItem>
    );
  }

  return (
    <List
      dataSource={paymentMethods}
      renderRow={renderRow}
    />
  );
}

export default PaymentMethodsList;