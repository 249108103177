/* eslint-disable react/jsx-key */
import React, { Component } from "react";
import { Page } from "react-onsenui";
import { connect } from "react-redux";
import RenderToolbar from "../RenderToolbar";
import {
  ListItem,
  Card,
  Row,
  Col,
  Button,
  List,
  BackButton,
} from "react-onsenui";
import * as UserActions from "../../actions/user";
import * as ShopActions from "../../actions/shop";
import Cart from "../cart/Cart";
import { bindActionCreators } from "redux";
import Loading from "../Loading";

class OrderHistory extends Component {
  handleOrderClicked = (e, order) => {
    const { pushPage, actions } = this.props;

    // Remove any existing items
    actions.clearCartItems();

    // Add each of this orders items to the cart
    order.products.forEach((p) => {
      let cartItem = {
        product_id: p.product.id,
        company_id: p.company_id,
        shop_id: p.product.shop_id,
        category_id: p.product.category_id,
        number: 1,
        variations: {
          1: p.variations[0].variation.id,
          2: p.variations[1].variation.id,
        },
        extras: p.extras.map((e) => e.extra.id),
      };

      if (p.variations[2]) {
        cartItem.variations[2] = p.variations[2].variation.id;
      }

      actions.addCartItem(cartItem);
    });

    actions.setSelectedShop(order.shop_id);

    pushPage(Cart, "cart", { quickOrder: true });
  };

  renderToolbar = () => {
    const { popPage } = this.props;

    return <RenderToolbar title="Quicky" backAction={popPage} />;
  };

  renderRow = (order, i) =>
    order.shop ? (
      <ListItem
        className="u-p-0"
        key={i}
        onClick={(e) => this.handleOrderClicked(e, order)}
      >
        <Card className="c-history__card u-mb-0 u-width-100">
          <Row>
            <Col width="70%">
              <div className="c-quickorder__order">
                <span className="c-quickorder__order-shop">
                  {order.shop.name}
                </span>
                <span className="c-quickorder__order-product">
                  Order:{order.id}
                </span>
                {order.products.map((p) => (
                  <>
                    <span className="c-quickorder__order-product">
                      {p.product.name}
                    </span>
                    <div className="c-quickorder__order-extras">
                      {p.variations.map((v) => (
                        <span className="c-quickorder__order-extra u-mr-025">
                          {v.variation.name}
                        </span>
                      ))}

                      {p.extras &&
                        p.extras.length > 0 &&
                        p.extras
                          .map((e) => {
                            // How many duplicates of this extra do we have?
                            const extrasCount = p.extras.filter(
                              (pExtra) => pExtra.extra.id === e.extra.id
                            ).length;
                            return extrasCount > 1
                              ? `${extrasCount} x ${e.extra.name}`
                              : e.extra.name;
                          })
                          .reduce(
                            (unique, extra) =>
                              unique.includes(extra)
                                ? unique
                                : [...unique, extra],
                            []
                          )
                          .map((extraText, i) => (
                            <span
                              key={`extra-${i}`}
                              className="c-quickorder__order-extra u-mr-025"
                            >
                              {extraText}
                            </span>
                          ))}
                    </div>
                  </>
                ))}
                <span className="c-quickorder__order-product">
                  {new Date(order.created_at).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  })}
                </span>
              </div>
            </Col>
            <Col width="30%">
              <div className="c-quickorder__button-group">
                <p className="c-quickorder__amount">
                  ${Number(order.discounted_amount).toFixed(2)}
                </p>
                <Button
                  onClick={(e) => this.handleOrderClicked(e, order)}
                  className="c-quickorder__button"
                >
                  Order Again
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </ListItem>
    ) : null;

  render() {
    const { orders } = this.props;

    return (
      <Page className="order-history" renderToolbar={this.renderToolbar}>
        <h1 className="u-text-center">Order History</h1>

        {orders ? (
          orders.length > 0 ? (
            <List dataSource={orders} renderRow={this.renderRow} />
          ) : (
            <Card>
              <p className="u-text-center">No previous orders</p>
            </Card>
          )
        ) : (
          <Loading />
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.user.orders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions, ...ShopActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
