import * as api from '../api/api';
import ajaxCallError from './ajaxCallError';
import { 
  GET_BEANS,
} from '../actionTypes';

export function getBeans() {
  return function(dispatch) {
    return api
      .getBeans()
      .then(
        response => dispatch(getBeansSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getBeansSuccess(response) {
  return {
    type: GET_BEANS,
    payload: response.data || {}
  }
}