export default {
  /**
   * Sort objects acending by their created at timestamp.
   * 
   * @param {*} a - Previous object.
   * @param {*} b - Next object.
   * @returns - Integer representation of moving the object backwards of forwards in the array.
   */
  createdAtAcending(a, b) {
    if (Date.parse(a.created_at) > Date.parse(b.created_at)) {
      return -1; 
    }
    return 1;
  }
}